import cn from 'classnames';
import { memo, useCallback } from 'react';

import { Button } from '@sravni/react-design-system';

import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { sendShowProductListClickEvent } from '@src/helpers/analyticsEvents/calculator';
import { scrollToElementById } from '@src/helpers/common';

import type { ICalculatorState } from '../../hooks/useCalculatorOffers';

import styles from './styles.module.scss';
import { useCalculation } from './useCalculation';

interface IProps {
    className?: string;
    values: ICalculatorState;
}

export const Calculation: React.FC<IProps> = memo(({ className, values }) => {
    const { legend, results } = useCalculation(values);

    const handleShowOffersClick = useCallback(() => {
        scrollToElementById(PRODUCT_LIST_ANCHOR);
        sendShowProductListClickEvent();
    }, []);

    return (
        <div className={cn(styles.container, className)}>
            {legend.length > 0 && (
                <div className={styles.row}>
                    <div className={styles.legend}>
                        {legend.map((item) => (
                            <div
                                className={styles.legend_item}
                                key={item.legendName}
                                style={{ '--color': item.color } as React.CSSProperties}
                            >
                                {item.legendName}
                            </div>
                        ))}
                    </div>

                    <div className={styles.legend_bar}>
                        {legend.map((item) => (
                            <div
                                className={styles.legend_bar_item}
                                key={item.id}
                                style={{ '--color': item.color, '--width': `${item.width}%` } as React.CSSProperties}
                            />
                        ))}
                    </div>
                </div>
            )}

            <div className={styles.row}>
                {results.length > 0 ? (
                    <div className={styles.calculation_block}>
                        {results.map((item) => (
                            <div className={styles.calculation_item} key={item.name}>
                                <div className={styles.calculation_item_name}>{item.name}</div>
                                <div
                                    className={styles.calculation_item_value}
                                    style={{ '--color': item.color } as React.CSSProperties}
                                >
                                    {item.value?.toLocaleString('ru')} {item.measure}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={styles.empty_block}>
                        Пожалуйста, укажите необходимые параметры, чтобы мы смогли рассчитать стоимость займа
                    </div>
                )}

                <Button size={60} block className={styles.button} variant="primary" onClick={handleShowOffersClick}>
                    Смотреть предложения
                </Button>
            </div>
        </div>
    );
});
