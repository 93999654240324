import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { NumberInput } from '@sravni/react-design-system';

import { AMOUNT_SETTINGS } from '@src/constants/filters';
import { validateByParams } from '@src/helpers/validate';

export interface IProps {
    className?: string;
    value?: number | null;
    name: string;
    label: string;
    placeholder?: string;
    min?: number;
    max?: number;
    error?: string;
    onChange: (value: number | undefined, name: string) => void;
    format?: string | ((value: string) => string);
    postfix?: string;
    analyticsEvent?: (label: string, value: string) => void;
}

const THOUSAND_SEPARATOR = ' ';

export const AmountControl: React.FC<IProps> = ({
    value,
    name,
    label,
    max = AMOUNT_SETTINGS.max,
    placeholder,
    onChange,
    className,
    error,
    postfix,
    min = 0,
    analyticsEvent,
    ...props
}) => {
    const validate = useMemo(() => validateByParams({ max }), [max]);
    const validatedValue = validate(value);

    const handleChange = useRef(onChange);

    useEffect(() => {
        // валидация: если в query-параметрах кто-то вручную исправит значение
        if (validatedValue !== value) {
            handleChange.current(validatedValue, name);
        }
    }, [name, validatedValue, value]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChangeAmountInput = useCallback(
        debounce((value = 0) => {
            if (value >= min && value <= max) {
                analyticsEvent?.(label, value.toString());
                handleChange.current(value, name);
            }
        }, 1000),

        [max, min, name, label, analyticsEvent],
    );

    const format = (value: string) =>
        new Intl.NumberFormat().format(Number(value.substring(0, 7).replace(/[,.]/g, '')));

    return (
        <NumberInput
            postfix={postfix}
            label={error || label}
            className={className}
            placeholder={placeholder}
            value={validatedValue}
            min={min}
            max={max}
            maxLength={AMOUNT_SETTINGS.maxLength}
            // @ts-ignore
            format={format}
            invalid={Boolean(error)}
            thousandSeparator={THOUSAND_SEPARATOR}
            inputMode="numeric"
            onChange={handleChangeAmountInput}
            {...props}
        />
    );
};
