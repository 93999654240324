export const AMOUNT = {
    label: 'Сумма займа',
    postfix: '₽',
    min: 1000,
    max: 100000,
    helperText: 'от 1 000 до 100 000 ₽',
};

export const RATE = {
    label: 'Ставка',
    postfix: '%',
    min: 0,
    max: 0.8,
    helperText: 'от 0 до 0,8%',
};

export const TERM = {
    label: 'На срок',
    min: 1,
    max: 365,
    helperText: 'от 1 до 365 дней',
};

export const PENALTY_TERM = {
    label: 'Срок просрочки',
    min: 1,
    max: 365,
    helperText: 'от 1 до 365 дней',
};
