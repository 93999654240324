import cn from 'classnames';
import { memo } from 'react';

import { Divider } from '@sravni/react-design-system/lib/Divider';
import { useIsMobile } from '@sravni/react-utils';

import { HelperText } from '@src/components/details/components/PageHeaderFilters/components/HelperText';
import { AmountControl } from '@src/components/ui/AmountControl';
import { RateControl } from '@src/components/ui/RateControl';
import { TermControl } from '@src/components/ui/TermControl';
import { FILTERS_NAMES } from '@src/constants/filters';
import {
    AMOUNT,
    RATE,
    TERM,
} from '@src/containers/calculator/PageHeader/Calculator/components/CalculatorFields/constants';
import { sendChangeInputValueEvent } from '@src/helpers/analyticsEvents/calculator';
import { getDaysPluralize } from '@src/helpers/strings';

import type { CalculatorParams, ICalculatorState } from '../../hooks/useCalculatorOffers';

import { Penalty } from './components/Penalty';
import styles from './styles.module.scss';

interface IProps {
    className?: string;
    values: ICalculatorState;
    onChange: (value: number | undefined, name: string) => void;
    onReset: (resetParams: CalculatorParams[]) => void;
}
export const CalculatorFields: React.FC<IProps> = memo(({ className, values, onChange, onReset }) => {
    const isMobile = useIsMobile();

    const amount = values[FILTERS_NAMES.AMOUNT];
    const term = values[FILTERS_NAMES.TERM];
    const rate = values[FILTERS_NAMES.RATE];
    const termPostfix = getDaysPluralize(term);

    return (
        <div className={cn(styles.container, className)}>
            <div className={cn(styles.input_block)}>
                <div>
                    <AmountControl
                        className={styles.input}
                        label={AMOUNT.label}
                        name={FILTERS_NAMES.AMOUNT}
                        value={amount}
                        onChange={onChange}
                        postfix={AMOUNT.postfix}
                        min={AMOUNT.min}
                        max={AMOUNT.max}
                        analyticsEvent={sendChangeInputValueEvent}
                    />
                    <HelperText text={AMOUNT.helperText} />
                </div>

                <div>
                    <RateControl
                        className={styles.input}
                        label={RATE.label}
                        name={FILTERS_NAMES.RATE}
                        value={rate}
                        onChange={onChange}
                        postfix={RATE.postfix}
                        min={RATE.min}
                        max={RATE.max}
                        analyticsEvent={sendChangeInputValueEvent}
                    />
                    <HelperText text={RATE.helperText} />
                </div>

                <div>
                    <TermControl
                        className={styles.input}
                        label={TERM.label}
                        name={FILTERS_NAMES.TERM}
                        value={term}
                        onChange={onChange}
                        postfix={termPostfix}
                        min={TERM.min}
                        max={TERM.max}
                        analyticsEvent={sendChangeInputValueEvent}
                    />
                    <HelperText text={TERM.helperText} />
                </div>
            </div>

            {isMobile && <Divider className={styles.divider} size={8} />}

            <div className={styles.penalty_block}>
                <Penalty values={values} onChange={onChange} onReset={onReset} />
            </div>
        </div>
    );
});
