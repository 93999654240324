import { useLayoutEffect, useRef } from 'react';

import type { ICalculatorState } from '@src/containers/calculator/PageHeader/Calculator/hooks/useCalculatorOffers';

type Props = {
    shouldBeScrolled: boolean;
    calculatorInputValues: ICalculatorState;
};

export const useScrollToCalculator = ({ shouldBeScrolled, calculatorInputValues }: Props) => {
    const calculatorBlockRef = useRef<HTMLDivElement>(null);
    const isFirstRender = useRef(true);

    useLayoutEffect(() => {
        if (!shouldBeScrolled) return;

        // Проверяем, если это первый рендер
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        // на каждое изменение в инпутах скроллим к элементу
        if (calculatorBlockRef.current) {
            calculatorBlockRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [shouldBeScrolled, calculatorBlockRef, calculatorInputValues]);

    return {
        calculatorBlockRef,
    };
};
