import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { usePageContext } from '@src/config';
import { CALCULATOR_INFO_BLOCK } from '@src/containers/calculator/PageHeader/Calculator/constants';
import { useSelectCreditsList } from '@src/reducers/microcredits';

export const useCalculatorInfo = () => {
    const { listKey } = usePageContext();
    const { total = 0 } = useSelectCreditsList(listKey);
    const { asPath } = useRouter();

    const vitrin = asPath.split('/zaimy/')[1]?.split('/')[0];

    return useMemo(() => {
        if (vitrin && CALCULATOR_INFO_BLOCK[vitrin]) {
            const { heading, subHeading } = CALCULATOR_INFO_BLOCK[vitrin];
            const formattedSubHeading = subHeading.replace('{количество_предложений}', String(total));

            return { heading, subHeading: formattedSubHeading };
        }
        return null;
    }, [vitrin, total]);
};
