import cn from 'classnames';
import { memo, useCallback } from 'react';

import { Typography } from '@sravni/react-design-system';

import type { IFilters } from '@src/@types/microcredits';
import { useCalculatorInfo } from '@src/containers/calculator/PageHeader/Calculator/hooks/useCalculatorInfo';
import { useScrollToCalculator } from '@src/containers/calculator/PageHeader/Calculator/hooks/useScrollToCalculator';

import { Calculation } from './components/Calculation/Calculation';
import { CalculatorFields } from './components/CalculatorFields';
import type { CalculatorParams } from './hooks/useCalculatorOffers';
import { useCalculatorOffers } from './hooks/useCalculatorOffers';
import styles from './styles.module.scss';

interface IProps {
    className?: string;
    filters: Partial<IFilters>;
    hasHeadingBlock: boolean;
}

const { Heading, Text } = Typography;

export const Calculator: React.FC<IProps> = memo(({ className, filters, hasHeadingBlock }) => {
    const { values, setValue, resetValues } = useCalculatorOffers(filters);

    const handleSetValue = useCallback(
        (value: number | undefined, name: string) => {
            setValue(name as CalculatorParams, value);
        },
        [setValue],
    );

    const { calculatorBlockRef } = useScrollToCalculator({
        shouldBeScrolled: hasHeadingBlock,
        calculatorInputValues: values,
    });
    const calculatorInfo = useCalculatorInfo();

    return (
        <div ref={calculatorBlockRef} className={cn(styles.container, className)}>
            <div className={styles.wrapper}>
                {hasHeadingBlock && Boolean(calculatorInfo) && (
                    <div className={styles.infoBlock}>
                        <Heading level={2}>{calculatorInfo.heading}</Heading>
                        <Text className="h-color-D60 h-pt-16" size={14}>
                            {calculatorInfo.subHeading}
                        </Text>
                    </div>
                )}
                <div className={styles.columnsWrapper}>
                    <div className={styles.column}>
                        <CalculatorFields values={values} onChange={handleSetValue} onReset={resetValues} />
                    </div>
                    <div className={styles.column}>
                        <Calculation values={values} />
                    </div>
                </div>
            </div>
        </div>
    );
});
