import { useMemo } from 'react';

import type { ICalculatorState } from '../../hooks/useCalculatorOffers';

enum CalculationParams {
    Amount = 'amount',
    Overpayment = 'overpayment',
    Penalty = 'penalty',
    Total = 'total',
}

const PERCENTS = 100;

const buildCalculation = (values: ICalculatorState) => {
    const { amount, rate, term, penaltyRate, penaltyTerm } = Object.entries(values).reduce<Record<string, number>>(
        (acc, [key, value]) => ({ ...acc, [key]: value || 0 }),
        {},
    );

    const overpayment = (amount * term * rate) / PERCENTS;
    const roundedOverpayment = Math.round(overpayment * PERCENTS) / PERCENTS;

    const penalty = (amount * penaltyRate * penaltyTerm) / PERCENTS;
    const roundedPenalty = Math.round(penalty * PERCENTS) / PERCENTS;

    const total = amount + overpayment + penalty;
    const roundedTotal = Math.round(total * PERCENTS) / PERCENTS;

    return [
        {
            id: CalculationParams.Amount,
            name: 'Сумма займа',
            legendName: 'Сумма займа',
            value: amount,
            measure: '₽',
            width: (amount / total) * PERCENTS,
            color: '#00AFFF',
        },
        {
            id: CalculationParams.Overpayment,
            name: 'Переплата по займу',
            legendName: 'Переплата',
            value: roundedOverpayment,
            measure: '₽',
            width: (roundedOverpayment / total) * PERCENTS,
            color: '#FF9500',
        },
        {
            id: CalculationParams.Penalty,
            name: 'Штраф за просрочку',
            legendName: 'Штраф',
            value: roundedPenalty,
            measure: '₽',
            width: (roundedPenalty / total) * PERCENTS,
            color: '#C11D1D',
        },
        {
            id: CalculationParams.Total,
            name: 'Общая сумма выплат',
            value: roundedTotal,
            measure: '₽',
        },
    ];
};

export const useCalculation = (calculatorState: ICalculatorState) => {
    const calculation = useMemo(() => buildCalculation(calculatorState), [calculatorState]);
    const legend = useMemo(() => calculation.slice(0, -1).filter((item) => item.value), [calculation]);
    const results = useMemo(() => calculation.filter((item) => item.value), [calculation]);

    return {
        legend,
        results,
    };
};
