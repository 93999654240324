export const CALCULATOR_INFO_BLOCK: Record<string, { heading: string; subHeading: string }> = {
    onlain: {
        heading: 'Калькулятор займов онлайн',
        subHeading:
            'Калькулятор займов онлайн — ваш надежный инструмент для расчета оптимальных условий. С помощью калькулятора займов онлайн вы быстро определите сумму переплаты и выберете выгодное предложение среди {количество_предложений} организаций со ставкой от 0% в день. Используйте калькулятор займов онлайн, чтобы получить деньги на карту или электронный кошелек уже сегодня.',
    },
    'na-kartu-bez-otkazov': {
        heading: 'Калькулятор займов без отказа',
        subHeading:
            'Калькулятор займов без отказа — ваш верный помощник в получении средств без лишних проверок. С помощью калькулятора займов без отказа вы сможете рассчитать оптимальные условия и выбрать из {количество_предложений} организаций со ставкой от 0% в день. Используйте калькулятор займов без отказа, чтобы получить деньги быстро и без отказов.',
    },
    's-plokhoj-kreditnoj-istoriej': {
        heading: 'Калькулятор займов с плохой кредитной историей',
        subHeading:
            'Калькулятор займов с плохой кредитной историей поможет вам найти лучшие предложения даже при низком кредитном рейтинге. С помощью калькулятора займов с плохой КИ вы быстро рассчитаете переплату и выберете выгодный вариант среди {количество_предложений} организаций со ставкой от 0% в день. Используйте калькулятор займов с плохой КИ для получения денег на карту без лишних препятствий.',
    },
    'bez-protsentov': {
        heading: 'Калькулятор займов без процентов',
        subHeading:
            'Калькулятор займов без процентов позволит вам оформить займ без переплаты. С помощью калькулятора займов без процентов вы сможете подобрать идеальные условия среди {количество_предложений} организаций, предлагающих ставки от 0% в день. Используйте калькулятор займов без процентов и получите деньги на карту совершенно бесплатно.',
    },
    top: {
        heading: 'Калькулятор лучших займов',
        subHeading:
            'Калькулятор лучших займов создан для тех, кто ищет самые выгодные условия. С помощью калькулятора лучших займов вы легко сравните предложения {количество_предложений} организаций со ставкой от 0% в день. Используйте калькулятор лучших займов, чтобы быстро получить деньги на карту по лучшим условиям.',
    },
    'pod-pts': {
        heading: 'Калькулятор займов под залог ПТС',
        subHeading:
            'Калькулятор займов под залог ПТС поможет вам рассчитать сумму, доступную под залог вашего автомобиля. С помощью калькулятора займов под залог ПТС вы узнаете условия {количество_предложений} организаций со ставкой от 0% в день. Используйте калькулятор займов под залог ПТС для быстрого получения крупной суммы на карту.',
    },
    'cherez-gosuslugi': {
        heading: 'Калькулятор займов через Госуслуги',
        subHeading:
            'Калькулятор займов через Госуслуги упрощает процесс оформления микрозайма с использованием вашего аккаунта на портале. С помощью калькулятора займов через Госуслуги вы найдете лучшие условия среди {количество_предложений} организаций со ставкой от 0% в день. Используйте калькулятор займов через Госуслуги для быстрого и удобного получения денег на карту.',
    },
    'na-kartu-do-zarplaty': {
        heading: 'Калькулятор займов до зарплаты',
        subHeading:
            'Калькулятор займов до зарплаты поможет вам пережить финансовые трудности до следующей получки. С помощью калькулятора займов до зарплаты вы выберете оптимальную сумму и срок среди {количество_предложений} организаций со ставкой от 0% в день. Используйте калькулятор займов до зарплаты, чтобы получить деньги на карту быстро и без лишних вопросов.',
    },
    dolgosrochnye: {
        heading: 'Калькулятор долгосрочных займов',
        subHeading:
            'Калькулятор долгосрочных займов рассчитан для тех, кто планирует серьезные покупки или инвестиции. С помощью калькулятора долгосрочных займов вы сможете оценить ежемесячные платежи и переплату по кредиту среди {количество_предложений} организаций со ставкой от 0% в день. Используйте калькулятор долгосрочных займов для грамотного финансового планирования.',
    },
    's-prosrochkami': {
        heading: 'Калькулятор займов с просрочками',
        subHeading:
            'Калькулятор займов с просрочками поможет вам найти варианты финансирования даже при наличии задолженностей. С помощью калькулятора займов с просрочками вы сможете подобрать подходящие условия среди {количество_предложений} организаций со ставкой от 0% в день. Используйте калькулятор займов с просрочками, чтобы получить необходимую сумму на карту несмотря на просрочки.',
    },
};
