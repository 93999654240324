import { memo, useState } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Button } from '@sravni/react-design-system/lib/Button';
import { Divider } from '@sravni/react-design-system/lib/Divider';
import { Icon } from '@sravni/react-design-system/lib/Icon';
import { Plus } from '@sravni/react-icons';
import { useIsMobile } from '@sravni/react-utils';

import { HelperText } from '@src/components/details/components/PageHeaderFilters/components/HelperText';
import { CustomTrash } from '@src/components/icons';
import { TermControl } from '@src/components/ui/TermControl';
import { FILTERS_NAMES } from '@src/constants/filters';
import { PENALTY_TERM } from '@src/containers/calculator/PageHeader/Calculator/components/CalculatorFields/constants';
import { sendAddPenaltyTermClickEvent, sendChangePenaltyTermValueEvent } from '@src/helpers/analyticsEvents/calculator';
import { getDaysPluralize } from '@src/helpers/strings';
import type {
    CalculatorParams,
    ICalculatorState,
} from 'src/containers/calculator/PageHeader/Calculator/hooks/useCalculatorOffers';

import styles from './styles.module.scss';

enum PenaltyState {
    Default = 'default',
    Days = 'days',
}

const { Text } = Typography;

interface IProps {
    className?: string;
    values: ICalculatorState;
    onChange: (value: number | undefined, name: string) => void;
    onReset: (resetParams: CalculatorParams[]) => void;
}

export const Penalty: React.FC<IProps> = memo(({ className, values, onChange, onReset }) => {
    const isMobile = useIsMobile();
    const [state, setState] = useState(PenaltyState.Default);

    const handleClose = () => {
        onReset([FILTERS_NAMES.PENALTY_TERM]);
        setState(PenaltyState.Default);
    };

    const penaltyTerm = values[FILTERS_NAMES.PENALTY_TERM];
    const pluralizePenaltyTerm = getDaysPluralize(penaltyTerm);

    const handleAddPenaltyTermClick = () => {
        setState(PenaltyState.Days);
        sendAddPenaltyTermClickEvent();
    };

    return (
        <div className={className}>
            {state === PenaltyState.Default ? (
                <Button block onClick={handleAddPenaltyTermClick}>
                    <Icon className={styles.plusIcon} size={20} icon={<Plus />} />
                    Добавить просрочку по займу
                </Button>
            ) : (
                <>
                    <div className={styles.row}>
                        <Text size={16} className="h-color-D60">
                            Просрочка по займу
                        </Text>
                        <Icon
                            onClick={handleClose}
                            className={styles.close_icon}
                            icon={<CustomTrash color="#C11D1D" />}
                            size={20}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.inputWrapper}>
                            {state === PenaltyState.Days && (
                                <TermControl
                                    className={styles.term_control}
                                    label={PENALTY_TERM.label}
                                    name={FILTERS_NAMES.PENALTY_TERM}
                                    value={penaltyTerm}
                                    onChange={onChange}
                                    postfix={pluralizePenaltyTerm}
                                    max={PENALTY_TERM.max}
                                    analyticsEvent={sendChangePenaltyTermValueEvent}
                                />
                            )}
                            <HelperText text={PENALTY_TERM.helperText} />
                        </div>
                    </div>

                    {isMobile && <Divider className={styles.divider} size={8} />}
                </>
            )}
        </div>
    );
});
